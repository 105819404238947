import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let mWorkPlatform = class mWorkPlatform extends Vue {
    constructor() {
        super(...arguments);
        /**
         * 组织列表
         */
        this.orgOptions = [];
        /**
         * 当前选择的组织
         */
        this.curOrg = '';
    }
    /**
     * 获取组织列表
     */
    async getOrgList() {
        const { data = [] } = await OrgManageService.getOrgList();
        if (Array.isArray(data)) {
            this.orgOptions = data?.map(({ id, name }) => ({
                name: name,
                value: id,
            }));
        }
        else {
            this.orgOptions = [];
        }
        let corpInfo = localStorage.getItem('corpInfo');
        if (corpInfo) {
            corpInfo = JSON.parse(corpInfo);
            this.curOrg = corpInfo.orgId;
            return;
        }
        this.curOrg = data[0]?.id;
    }
    /**
     * 设置组织
     */
    async setOrg(id) {
        // console.log(id);
        if (id === this.curOrg) {
            return;
        }
        const { success, data = {}, errMessage = '组织切换失败！', } = await OrgManageService.setOrg({ orgId: id });
        if (success) {
            this.$toast.show('组织切换成功！');
            this.curOrg = id;
            // 流程组需要用到
            localStorage.setItem('corpInfo', JSON.stringify(data));
            this.updateIsSetOrg(true);
            this.getLoginInfo();
            setTimeout(() => {
                this.$router.go(-1);
            }, 500);
        }
        else {
            this.$message.warning(errMessage);
        }
    }
    async getLoginInfo() {
        const res = await OrgManageService.getLoginInfo();
        if (res.success) {
            const data = res.data || {};
            const user = {
                userInfo: { id: data.id, name: data.name, username: data.username, type: 3 },
                departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: 1 },
            };
            // 运行态的选人控件默认值需要用到
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
    mounted() {
        window.document.title = '切换组织';
        this.getOrgList();
    }
};
__decorate([
    workPlatformModule.Mutation('updateIsSetOrg')
], mWorkPlatform.prototype, "updateIsSetOrg", void 0);
mWorkPlatform = __decorate([
    Component({
        name: 'mWorkPlatformSwitchTeam',
        components: {},
    })
], mWorkPlatform);
export default mWorkPlatform;
